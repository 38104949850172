import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { BaseModal } from './_BaseModal'
import { Button } from 'components/atoms/Button'
import { getAll as getCompanyData, updateMemo } from 'api/company.api'
import { Memo } from 'components/atoms/Memo'
import { useRecoilState, useSetRecoilState } from 'recoil'
import useModal from 'hooks/useModals'
import { CompanyState } from 'recoil/atoms/companies.atom'
import swal from 'sweetalert2'

export const MemoModal = (): JSX.Element | null => {
  const [localText, setLocalText] = useState<string>('')
  const [isVisible, setIsVisible] = useModal('memo')
  const [companies] = useRecoilState(CompanyState)
  const setCompanyData = useSetRecoilState(CompanyState)
  const selectCompany = companies.find((data) => data.id === isVisible)
  useEffect(() => {
    setLocalText('')
  }, [])
  const memoUpdateHandler = async () => {
    if (selectCompany) {
      const requestParams = {
        id: selectCompany.id,
        version: selectCompany.version,
        memo: localText
      }
      await updateMemo(requestParams).then(() => {
        getCompanyData().then(async (data) => {
          await swal.fire({
            icon: 'info',
            text: 'メモを更新しました。'
          })
          setCompanyData(data.data)
          setLocalText('')
        })
      })
    }
  }
  return isVisible !== null && selectCompany !== undefined ? (
    <BaseModal>
      <Stack
        spacing={2}
        sx={{ borderRadius: '5px', background: '#f3f5f7 ', padding: '20px' }}
      >
        <div>{'会社名：' + selectCompany.name}</div>
        <Memo
          value={localText}
          changeHandler={(param) => {
            setLocalText(param.target.value)
          }}
          placeHolder={'先方担当者：\n注力情報：\n業界：'}
        />
        <Memo disabled value={selectCompany.memo} />
        <Stack direction={'row'} justifyContent={'space-around'}>
          <div className={'w-40'}>
            <Button
              color="peach"
              clickHandler={memoUpdateHandler}
              label={'更新'}
            />
          </div>
          <div className={'w-40'}>
            <Button
              color="dark"
              clickHandler={() => {
                setIsVisible(null)
              }}
              label={'閉じる'}
            />
          </div>
        </Stack>
      </Stack>
    </BaseModal>
  ) : null
}
