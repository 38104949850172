import { Stack } from '@mui/material'
import { Button } from 'components/atoms/Button'
import { TableInput } from 'components/atoms/inputs/TableInput'
import { Control, UseFormRegister, useFieldArray } from 'react-hook-form'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { CompanyEntityType } from 'types/api/company'
import { RequiredMark } from 'components/atoms/RequiredMark'

type Props = {
  register: UseFormRegister<CompanyEntityType>
  control: Control<CompanyEntityType, any>
}
export const SelectedSalesPersons = (props: Props): JSX.Element => {
  const { register, control } = props
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sales'
  })

  const headerStyle = 'font-medium bg-gray-300 h-[30px]'
  const bodyStyle = 'font-medium bg-[#ffffff] h-[30px]'
  const TableCellHeader = (props: {
    label: string
    deleteArea?: boolean
    span?: number
    required?: boolean
  }): JSX.Element => {
    const { label, deleteArea, span = 1, required } = props
    const commonStyle = 'whitespace-nowrap font-medium border-2 border-gray-400'

    return deleteArea ? (
      <td colSpan={span} className={commonStyle + ' w-[35px]'}>
        <span className={'p-1'}>{label}</span>
      </td>
    ) : span === 1 ? (
      <td colSpan={span} className={commonStyle + ` w-[150px]`}>
        <span className={'p-1'}>{label}</span>
        {required ? <RequiredMark /> : null}
      </td>
    ) : (
      <td colSpan={span} className={commonStyle + ` w-[300px]`}>
        <span className={'p-1'}>{label}</span>
        {required ? <RequiredMark /> : null}
      </td>
    )
  }
  const TableCell = (props: {
    children: JSX.Element
    deleteArea?: boolean
  }): JSX.Element => {
    const { children, deleteArea } = props
    const commonStyle =
      'whitespace-nowrap font-medium border-2 border-gray-400 p-1'
    return (
      <td className={commonStyle + (deleteArea ? ' w-[35px]' : ' w-[150px]')}>
        {children}
      </td>
    )
  }
  const isMaxSales = fields.length >= 10
  return (
    <>
      <Stack
        className={'max-h-80 overflow-y-scroll'}
        direction="column"
        spacing={2}
      >
        <table className={'w-max'}>
          <thead className={headerStyle}>
            <tr>
              <TableCellHeader label={'名前'} span={2} required />
              <TableCellHeader label={'部署'} />
              <TableCellHeader label={'役職'} />
              <TableCellHeader label={'電話番号'} />
              <TableCellHeader label={'メールアドレス'} required />
              <TableCellHeader label={''} deleteArea />
            </tr>
          </thead>
          <tbody className={bodyStyle}>
            {fields.map((field, index) => {
              return (
                !field.is_deleted && (
                  <tr key={index}>
                    <TableCell>
                      <TableInput
                        register={register}
                        label={`sales.${index}.last_name`}
                        placeholder={'姓'}
                      />
                    </TableCell>
                    <TableCell>
                      <TableInput
                        register={register}
                        label={`sales.${index}.first_name`}
                        placeholder={'名'}
                      />
                    </TableCell>
                    <TableCell>
                      <TableInput
                        register={register}
                        label={`sales.${index}.department`}
                      />
                    </TableCell>
                    <TableCell>
                      <TableInput
                        register={register}
                        label={`sales.${index}.post`}
                      />
                    </TableCell>
                    <TableCell>
                      <TableInput
                        register={register}
                        label={`sales.${index}.cell_phone`}
                      />
                    </TableCell>
                    <TableCell>
                      <TableInput
                        register={register}
                        label={`sales.${index}.mail`}
                      />
                    </TableCell>
                    <TableCell deleteArea>
                      {fields.filter((data) => !data.is_deleted).length > 1 ? (
                        <RemoveCircleOutlineIcon
                          sx={{ color: '#ff012b' }}
                          className={'cursor-pointer'}
                          onClick={() => {
                            const { id, ...props } = { ...field }
                            remove(index)
                            append({ ...props, id: null, is_deleted: true })
                          }}
                        />
                      ) : (
                        <RemoveCircleOutlineIcon sx={{ color: '#ddd' }} />
                      )}
                    </TableCell>
                  </tr>
                )
              )
            })}
          </tbody>
        </table>
      </Stack>
      <div className={'flex gap-x-3 w-20'}>
        <Button
          color={'green'}
          label={'追加'}
          type={'button'}
          disabled={isMaxSales}
          clickHandler={() =>
            append({
              id: null,
              first_name: '',
              last_name: '',
              department: '',
              cell_phone: '',
              mail: '',
              is_deleted: false
            })
          }
        />
      </div>
    </>
  )
}
