import { Stack } from '@mui/material'
import { FrequencySelector } from 'components/atoms/selects/FrequencySelector'
import { RequiredMark } from 'components/atoms/RequiredMark'
import { TableInput } from 'components/atoms/inputs/TableInput'
import { TagSelector } from 'components/atoms/selects/TagSelector'
import { UseFormGetValues, UseFormRegister } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import { AuthState } from 'recoil/atoms/auth.atom'
import { CompanyEntityType } from 'types/api/company'
import { dateToString } from 'utils/dateToString'
import { TableInputDate } from 'components/atoms/inputs/TableInputDate'
import { BooleanSelector } from 'components/atoms/selects/BooleanSelector'

type Props = {
  register: UseFormRegister<CompanyEntityType>
  getValues: UseFormGetValues<CompanyEntityType>
  isNew: boolean
}
export const SelectedCompany = (props: Props): JSX.Element => {
  const { register, getValues, isNew } = props
  const headerStyle = 'font-medium bg-gray-300 h-[30px]'
  const headerCompanyStyle = 'font-medium bg-gray-300 h-[30px] w-[450px]'
  const bodyStyle = 'font-medium bg-[#ffffff] h-[30px]'

  const [auth] = useRecoilState(AuthState)
  const TableCellHeader = (props: {
    label: string
    required?: boolean
    col?: number
  }): JSX.Element => {
    const { label, required, col = 1 } = props
    return (
      <td
        className={`whitespace-nowrap font-medium border-2 border-gray-400 w-[${col * 150}px]`}
      >
        <span className={'p-1'}>{label}</span>
        {required ? <RequiredMark /> : null}
      </td>
    )
  }
  const TableCell = (props: { children: JSX.Element }): JSX.Element => {
    const { children } = props
    return <td className={`border-2 border-gray-400 p-1`}>{children}</td>
  }
  return (
    <Stack direction="column">
      <Stack spacing={0.6}>
        <table className={'w-max'}>
          <thead className={headerCompanyStyle}>
            <tr>
              <TableCellHeader label={'会社名'} required={isNew} col={3} />
            </tr>
          </thead>
          <tbody className={bodyStyle}>
            <tr>
              <TableCell>
                <TableInput
                  register={register}
                  label={'name'}
                  disabled={
                    getValues('id') !== undefined &&
                    auth !== null &&
                    auth !== 'ADMIN'
                  }
                />
              </TableCell>
            </tr>
          </tbody>
        </table>
        <table className={'w-max'}>
          <thead className={headerStyle}>
            <tr>
              <TableCellHeader label={'発起'} />
              <TableCellHeader label={'訪問頻度'} />
              <TableCellHeader label={'配信タグ'} />
              <TableCellHeader label={'得意分野'} required={!isNew} />
              <TableCellHeader label={'得意業界'} required={!isNew} />
            </tr>
          </thead>
          <tbody className={bodyStyle}>
            <tr>
              <TableCell>
                <span className="text-gray-400">
                  {dateToString(getValues('update_at'))}
                </span>
              </TableCell>
              <TableCell>
                <FrequencySelector register={register} />
              </TableCell>
              <TableCell>
                <TagSelector register={register} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'specialty'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'specialty'} />
              </TableCell>
            </tr>
          </tbody>
        </table>
        <table className={'w-max'}>
          <thead className={headerStyle}>
            <tr>
              <TableCellHeader label={'郵便番号'} required={!isNew} />
              <TableCellHeader label={'住所'} required={!isNew} />
              <TableCellHeader label={'固定番号'} required={!isNew} />
              <TableCellHeader label={'ホームページ'} required={!isNew} />
              <TableCellHeader label={'設立年月日'} required={!isNew} />
            </tr>
          </thead>
          <tbody className={bodyStyle}>
            <tr>
              <TableCell>
                <TableInput register={register} label={'post_code'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'address'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'home_phone'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'homepage'} />
              </TableCell>
              <TableCell>
                <TableInputDate register={register} label={'establishment'} />
              </TableCell>
            </tr>
          </tbody>
        </table>
        <table className={'w-max'}>
          <thead className={headerStyle}>
            <tr>
              <TableCellHeader label={'インボイス'} required={!isNew} />
              <TableCellHeader label={'営業人数'} required={!isNew} />
              <TableCellHeader label={'プロパー数'} required={!isNew} />
              <TableCellHeader label={'BP数'} required={!isNew} />
              <TableCellHeader label={'配信アドレス'} required={!isNew} />
            </tr>
          </thead>
          <tbody className={bodyStyle}>
            <tr>
              <TableCell>
                <TableInput register={register} label={'invoice'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'sales_value'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'proper_value'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'bp_value'} />
              </TableCell>
              <TableCell>
                <TableInput register={register} label={'deliver_address'} />
              </TableCell>
            </tr>
          </tbody>
        </table>
        <table className={'w-max'}>
          <thead className={headerStyle}>
            <tr>
              <TableCellHeader label={'派遣免許有無'} required={!isNew} />
              <TableCellHeader label={'派遣免許番号'} required={!isNew} />
              <TableCellHeader label={'Pマーク'} required={!isNew} />
              <TableCellHeader label={'ISMS'} required={!isNew} />
            </tr>
          </thead>
          <tbody className={bodyStyle}>
            <tr>
              <TableCell>
                <BooleanSelector register={register} tag={'dispatch_license'} />
              </TableCell>
              <TableCell>
                <TableInput
                  register={register}
                  label={'dispatch_license_number'}
                />
              </TableCell>
              <TableCell>
                <BooleanSelector register={register} tag={'p_mark'} />
              </TableCell>
              <TableCell>
                <BooleanSelector register={register} tag={'isms'} />
              </TableCell>
            </tr>
          </tbody>
        </table>
      </Stack>
    </Stack>
  )
}
