import axios from 'axios'

const commonAPIParam = {
  baseURL: 'http://127.0.0.1:8000',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE',
    'Access-Control-Allow-Headers': 'Content-Type'
  }
}
export const client = axios.create(commonAPIParam)
client.interceptors.request.use((config) => {
  return config
})
client.interceptors.response.use(
  (response) => response,
  (error: any) => {
    throw new Error(error.response.data.message)
  }
)

export const guardClient = axios.create(commonAPIParam)
guardClient.interceptors.request.use((config) => {
  const auth = localStorage.getItem('auth')
  if (auth) {
    const authJson = JSON.parse(auth)
    config.headers.Authorization = `Bearer ${authJson.token}`
  } else {
    console.error('access token')
  }
  return config
})
guardClient.interceptors.response.use(
  (response) => response,
  (error: any) => {
    throw new Error(error.response.data.message)
  }
)
