import { UseFormRegister } from 'react-hook-form'
import { CompanyEntityType } from 'types/api/company'

type Props = {
  register: UseFormRegister<CompanyEntityType>
}
export const TagSelector = (props: Props): JSX.Element => {
  const { register } = props
  const tagList = [
    { key: 'PROJECT_PERSONNEL', value: '案件人材' },
    { key: 'PROJECT', value: '案件' },
    { key: 'PROJECT_DEVELOPMENT', value: '案件（開発）' },
    { key: 'PROJECT_FOUNDATION', value: '案件（基盤）' },
    { key: 'HUMAN_RESOURCES_BP', value: '人材（BP）' },
    { key: 'HUMAN_RESOURCES_P', value: '人材（P）' },
    { key: 'NO_DISTRIBUTION', value: '配信なし' },
    { key: '', value: '-' }
  ]
  return (
    <select
      className={'h-full w-full border-none outline-none'}
      {...register('tag')}
    >
      {tagList.map((data) => (
        <option value={data.key} key={data.key}>
          {data.value}
        </option>
      ))}
    </select>
  )
}
