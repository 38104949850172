import { useRecoilState, SetterOrUpdater } from 'recoil'
import { ModalState, ModalType } from 'recoil/atoms/modal.atom'

type Response = [
  number | null | undefined | boolean,
  SetterOrUpdater<number | null | undefined | boolean>
]

const useModal = (modalType: ModalType): Response => {
  const [isVisible, setIsVisible] = useRecoilState(ModalState(modalType))

  return [isVisible, setIsVisible]
}

export default useModal
