import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material'
import { AuthPage } from 'components/templates/AuthPage'
import { login } from 'api/auth.api'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/inputs/Input'
import { authRequestType } from 'types/api/auth'
import swal from 'sweetalert2'
import { useRecoilState } from 'recoil'
import { AuthState } from 'recoil/atoms/auth.atom'

export const Login = (): JSX.Element => {
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm({
    defaultValues: { email: 'test@test.com', password: 'test' }
  })
  const [, setAuth] = useRecoilState(AuthState)

  const onSubmit = async (data: authRequestType) => {
    try {
      await login(data).then((response: any) => {
        const token = response.data.data
        localStorage.setItem('auth', JSON.stringify(token))
        setAuth(response.data.data.authority)
        if (token) navigate('/customer-introduction')
      })
    } catch (error: any) {
      await swal.fire({ icon: 'error', text: error.message })
    }
  }
  return (
    <AuthPage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={'column'}
          spacing={3}
          width={'300px'}
          padding={'10px'}
        >
          <Input
            control={control}
            name={'email'}
            type={'text'}
            color={'white'}
            placeholder={'Email'}
            autoComplete={'on'}
            required
          />
          <Input
            control={control}
            name={'password'}
            type={'password'}
            color={'white'}
            placeholder={'Password'}
            required
          />
          <Button color={'peach'} label={'Login'} />
        </Stack>
      </form>
    </AuthPage>
  )
}
