import { HTMLInputTypeAttribute } from 'react'
import { useController, Control } from 'react-hook-form'
import { classNames } from 'utils/classNames'

type Props = {
  control: Control<any, any>
  name: string
  type: HTMLInputTypeAttribute
  color: 'indigo' | 'white'
  placeholder: string
  required?: boolean
  autoComplete?: 'off' | 'on'
}

export const Input = (props: Props) => {
  const {
    control,
    name,
    type,
    color,
    placeholder,
    required = false,
    autoComplete = 'off'
  } = props
  const { field } = useController({
    control,
    name,
    rules: { required: required }
  })

  const baseStyle =
    'shadow text-lg p-2 rounded-sm border-2 border-solid outline-none'
  const inputColors = {
    white: 'bg-white text-gray border-[#f3f5f7] focus:brightness-110',
    indigo: 'bg-[#371260] text-white border-[#5f2666] focus:brightness-125'
  }
  return (
    <input
      {...field}
      className={classNames([baseStyle, inputColors[color]])}
      type={type}
      autoComplete={autoComplete}
      placeholder={placeholder}
    />
  )
}
