type Props = {
  clickHandler: () => void
  label: string
}
export const FunctionLabel = (props: Props): JSX.Element => {
  const { clickHandler, label } = props

  return (
    <span
      className={
        'text-sm text-[#235CFF] border-b border-[#235CFF] cursor-pointer '
      }
      onClick={clickHandler}
    >
      {label}
    </span>
  )
}
