import { CompanyEntityType } from 'types/api/company'
import { checkSalesPersons } from './checkSalesPersons'

const isEntity = (data: string | null): boolean => {
  return data === null || data === ''
}
export const checkUpdateCompany = (data: CompanyEntityType): void => {
  // 会社情報
  if (
    isEntity(data.address) ||
    isEntity(data.home_phone) ||
    isEntity(data.post_code) ||
    isEntity(data.specialty) ||
    isEntity(data.tag) ||
    isEntity(data.homepage)
  )
    throw new Error('会社情報の必須項目が入力されていません。')

  // 営業情報
  checkSalesPersons(data.sales)
}
