import { UseFormRegister } from 'react-hook-form'
import { CompanyEntityType } from 'types/api/company'

type Props = {
  register: UseFormRegister<CompanyEntityType>
}
export const FrequencySelector = (props: Props): JSX.Element => {
  const { register } = props
  const frequencyList = [
    { key: 'HIGH', value: '高' },
    { key: 'MIDDLE', value: '中' },
    { key: 'LOW', value: '低' },
    { key: 'NONE', value: '-' }
  ]
  return (
    <select
      className={'h-full w-full border-none outline-none'}
      {...register('frequency')}
    >
      {frequencyList.map((data) => (
        <option value={data.key} key={data.key}>
          {data.value}
        </option>
      ))}
    </select>
  )
}
