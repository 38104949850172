/**
 *
 * @param tagType
 * @returns
 */
export const tagConvert = (tagType: string): string => {
  const tagConvert: { [K: string]: string } = {
    PROJECT_PERSONNEL: '案件人材',
    PROJECT: '案件',
    PROJECT_DEVELOPMENT: '案件（開発）',
    PROJECT_FOUNDATION: '案件（基盤）',
    HUMAN_RESOURCES_BP: '人材（BP）',
    HUMAN_RESOURCES_P: '人材（P）',
    NO_DISTRIBUTION: '配信なし'
  }
  return tagConvert[tagType]
}
