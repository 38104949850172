import { ChangeEvent } from 'react'
import { Stack } from '@mui/material'
import { UseFormRegister } from 'react-hook-form'
import { CompanyEntityType } from 'types/api/company'

type Props = {
  disabled?: boolean
  value?: string
  changeHandler?: (params: ChangeEvent<HTMLTextAreaElement>) => void
  register?: UseFormRegister<CompanyEntityType>
  placeHolder?: string
}
export const Memo = (props: Props): JSX.Element => {
  const { disabled, value, changeHandler, register, placeHolder = '' } = props
  const commonStyle =
    'block resize-none p-2.5 w-full h-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'

  return (
    <Stack spacing={2} sx={{ width: '600px', height: '350px' }}>
      {register ? (
        <textarea
          {...register('memo')}
          className={commonStyle}
          placeholder={placeHolder}
        />
      ) : (
        <textarea
          value={value}
          className={commonStyle}
          disabled={disabled}
          onChange={changeHandler}
          placeholder={placeHolder}
        />
      )}
    </Stack>
  )
}
