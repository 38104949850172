import { UseFormRegister } from 'react-hook-form'
import { CompanyEntityType } from 'types/api/company'

type Props = {
  register: UseFormRegister<CompanyEntityType>
  disabled?: boolean
  label: any
  rules?: any
  placeholder?: string
}
export const TableInputDate = (props: Props): JSX.Element => {
  const { register, disabled, label, rules, placeholder = '' } = props
  const commonStyle = 'w-full appearance-none outline-none border-b-2'
  const disabledStyle = 'bg-white text-gray-400 border-none'
  const design = [commonStyle, disabled ? disabledStyle : ''].join(' ')
  return (
    <input
      {...register(label, rules)}
      type="date"
      className={design}
      disabled={disabled}
      autoComplete="none"
      placeholder={placeholder}
    />
  )
}
