import { signupRequestType } from 'types/api/user'
import { client } from './_axios'

const API_USERS = '/users'
/**
 * ユーザーの追加
 * @param request
 * @returns
 */
export const signUp = async (request: signupRequestType) => {
  const response = await client.post(API_USERS + '/signup', request)
  return response.data
}
