import { CircularProgress } from '@mui/material'
import { useRecoilState } from 'recoil'
import { LoadingState } from 'recoil/atoms/loading.atom'

export const LoadingOverlay = (): JSX.Element | null => {
  const [isLoading] = useRecoilState(LoadingState)

  return isLoading ? (
    <div
      className={'fixed top-0 left-0 w-full h-full bg-black z-20 opacity-70'}
    >
      <CircularProgress
        color="secondary"
        className={'absolute inset-y-2/4	inset-x-2/4		mt-0 ml-auto'}
      />
    </div>
  ) : null
}
