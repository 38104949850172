export const dateToString = (text: string): string => {
  if (text === '') return '-'
  const updateDate = new Date(text)
  const dateList = [
    updateDate.getFullYear().toString(),
    (updateDate.getMonth() + 1).toString().padStart(2, '0'),
    updateDate.getDate().toString().padStart(2, '0')
  ]
  const timeList = [
    updateDate.getHours().toString().padStart(2, '0'),
    updateDate.getMinutes().toString().padStart(2, '0')
  ]
  return dateList.join('/') + ' ' + timeList.join(':')
}
