import { Routing } from 'routing'
import { RecoilRoot } from 'recoil'
import { Suspense } from 'react'

export const App = () => {
  return (
    <RecoilRoot>
      <Routing />
    </RecoilRoot>
  )
}
