import { CompanyEntityType } from 'types/api/company'

type DataType = {
  companyName: string
}

const downloadCSV = (dataList: DataType[]) => {
  const topHeader = [
    '会社情報',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '営業情報'
  ]
  const companyHeader = [
    '会社名',
    '発起',
    '訪問頻度',
    '配信タグ',
    '得意分野',
    '得意業界',
    '郵便番号',
    '住所',
    '固定番号',
    'ホームページ',
    '設立年月日',
    'インボイス',
    '営業人数',
    'プロパー数',
    'BP数',
    '配信アドレス',
    '派遣免許有無',
    '派遣免許番号',
    'Pマーク',
    'ISMS'
  ]
  const salesHeader = ['名前', '部署', '役職', '電話番号', 'メールアドレス']
  const header =
    topHeader.join(',') +
    '\n' +
    companyHeader.join(',') +
    ',' +
    salesHeader.join(',') +
    '\n'

  let dataString = header + '\r\n'
  // オブジェクトの中身を取り出してカンマ区切りにする
  for (let data of dataList) {
    dataString += data.companyName
    dataString += '\r\n'
  }

  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, dataString], { type: 'text/csv' })
  const objectUrl = URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')
  const fileName = 'template.csv'
  downloadLink.download = fileName
  downloadLink.href = objectUrl

  downloadLink.click()
  downloadLink.remove()
}
export const downloadCSVTemplate = () => {
  downloadCSV([])
}
export const downloadCompanyCSV = (companies: CompanyEntityType[]) => {}
