import { UseFormRegister } from 'react-hook-form'
import { UserEntityType } from 'types/api/user'

type Props = {
  register: UseFormRegister<UserEntityType>
}
export const AuthoritySelector = (props: Props): JSX.Element => {
  const { register } = props
  const authorityList = [
    { key: 'ADMIN', value: '管理者' },
    { key: 'GENERAL', value: '一般' }
  ]
  return (
    <select
      className={
        'h-full w-full shadow text-lg p-2 rounded-sm border-2 border-solid outline-none'
      }
      {...register('authority')}
    >
      {authorityList.map((data) => (
        <option value={data.key} key={data.key}>
          {data.value}
        </option>
      ))}
    </select>
  )
}
