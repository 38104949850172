import { classNames } from 'utils/classNames'

type Props = {
  searchText: string
  setHandler: React.Dispatch<React.SetStateAction<string>>
}

export const SearchInput = (props: Props) => {
  const { searchText, setHandler } = props
  const baseStyle =
    'text-md p-2 rounded-sm border-2 border-solid border-gray-200 outline-none w-full'
  const inputColors = 'bg-[#fff] text-gray border-[#f3f5f7] '

  return (
    <input
      type={'text'}
      value={searchText}
      onChange={(event) => {
        setHandler(event.target.value)
      }}
      placeholder={'検索する文字列を入力'}
      className={classNames([baseStyle, inputColors])}
    />
  )
}
