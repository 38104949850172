import { Stack } from '@mui/material'
import { BaseModal } from './_BaseModal'
import { Button } from 'components/atoms/Button'
import useModal from 'hooks/useModals'
import swal from 'sweetalert2'
import { UserEntityType } from 'types/api/user'
import { useForm } from 'react-hook-form'
import { signUp } from 'api/user.api'
import { Input } from 'components/atoms/inputs/Input'
import { AuthoritySelector } from 'components/atoms/AuthoritySelector'

export const SignUp = (): JSX.Element | null => {
  const [isVisible, setIsVisible] = useModal('signup')

  const { handleSubmit, control, register } = useForm<UserEntityType>({
    defaultValues: { email: '', password: '', authority: 'GENERAL' }
  })
  const onSubmit = async (data: any) => {
    try {
      await signUp(data).then(async () => {
        await swal.fire({
          icon: 'info',
          text: 'ユーザーの登録が完了しました。'
        })
        setIsVisible(null)
      })
    } catch (err: any) {
      await swal.fire({ icon: 'error', text: err.message })
    }
  }
  return isVisible !== null ? (
    <BaseModal>
      <Stack
        spacing={2}
        sx={{ borderRadius: '5px', background: '#f3f5f7 ', padding: '20px' }}
      >
        <div>{'ユーザー登録'}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={'column'}
            spacing={3}
            width={'400px'}
            padding={'10px'}
          >
            <Input
              control={control}
              name={'email'}
              type={'text'}
              color={'white'}
              placeholder={'Email'}
              required
            />
            <Input
              control={control}
              name={'password'}
              type={'text'}
              color={'white'}
              placeholder={'Password'}
              required
            />
            <AuthoritySelector register={register} />
            <Stack direction={'row'} justifyContent={'space-around'}>
              <div className={'w-40'}>
                <Button color={'green'} label={'登録'} />
              </div>
              <div className={'w-40'}>
                <Button
                  color="dark"
                  clickHandler={() => {
                    setIsVisible(null)
                  }}
                  label={'閉じる'}
                />
              </div>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </BaseModal>
  ) : null
}
