import { classNames } from 'utils/classNames'

type ButtonType = {
  color: 'green' | 'yellow' | 'dark' | 'peach' | 'red'
  label: string
  clickHandler?: () => void
  disabled?: boolean
  type?: 'button'
}
export const Button = (props: ButtonType): JSX.Element => {
  const { color, label, clickHandler, disabled, type = 'submit' } = props
  const baseStyle =
    'w-full h-10 border-2 border-solid text-lg rounded whitespace-no-wrap'
  const setColors = {
    peach:
      'text-white bg-gray-900 border-gray-900 hover:bg-[#FE6BB0] hover:border-[#FE6BB0]',
    green: 'text-white bg-green-500 border-green-500 hover:brightness-110',
    yellow: 'bg-yellow-500 border-yellow-500 hover:brightness-110',
    dark: 'text-white bg-gray-600 border-gray-600 hover:brightness-75',
    red: 'text-white bg-red-600 border-red-600 hover:brightness-75'
  }
  return (
    <button
      type={type}
      className={classNames([
        baseStyle,
        disabled ? 'text-[#ddd] bg-gray-600 border-gray-600' : setColors[color]
      ])}
      onClick={clickHandler}
      disabled={disabled}
    >
      {label}
    </button>
  )
}
