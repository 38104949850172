import { atomFamily } from 'recoil'

export type ModalType = 'memo' | 'detail' | 'signup'
export const ModalState = atomFamily<
  number | undefined | null | boolean,
  ModalType
>({
  key: 'modal',
  default: null
})
