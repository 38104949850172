import { client } from './_axios'
import { authRequestType, LoginResponse } from 'types/api/auth'

const API_AUTH = '/auth'
/**
 * ログイン
 * @param request
 * @returns
 */
export const login = async (request: authRequestType) => {
  return await client.post(API_AUTH + '/login', request)
}
