import React from 'react'
import { Stack } from '@mui/material'
import { AuthPage } from 'components/templates/AuthPage'

export const NotFoundPage = (): JSX.Element => {
  return (
    <AuthPage>
      <Stack alignItems={'center'} height={'100%'} width={'100%'}>
        {'Not Found'}
      </Stack>
    </AuthPage>
  )
}
