import { useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellClassParams, ColDef } from 'ag-grid-community'
import { FunctionLabel } from 'components/atoms/FunctionLabel'
import { tagConvert } from 'utils/tagConvert'
import { getAll as getCompanyList } from 'api/company.api'
import { dateToString } from 'utils/dateToString'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { SalesEntityType } from 'types/api/sales'
import useModal from 'hooks/useModals'
import { useRecoilState } from 'recoil'
import { CompanyState } from 'recoil/atoms/companies.atom'

type Props = {
  searchText: string
}
export const CompanyList = (props: Props): JSX.Element => {
  const { searchText } = props
  const [, setIsDetailVisible] = useModal('detail')
  const [, setIsMemoVisible] = useModal('memo')
  const [companies, setCompanies] = useRecoilState(CompanyState)
  const [displayData, setDisplayData] = useState<any[]>([])

  /**
   *
   */
  useEffect(() => {
    getCompanyList()
      .then((data) => {
        setCompanies(data.data)
      })
      .catch((error) => {})
  }, [setCompanies])
  /**
   *
   */
  useEffect(() => {
    if (searchText !== '') {
      const filterData = companies.filter((row) => {
        const { sales, ...params } = row
        const valueArray = Object.values(params)
          .filter((value) => !(value === null || typeof value === 'boolean'))
          .map((value) => String(value))
          .filter((word) => word.indexOf(searchText) !== -1)

        const salesSearch = sales.filter((sales) => {
          const valueArray = Object.values(sales)
            .filter((value) => !(value === null || typeof value === 'boolean'))
            .map((value) => String(value))
            .filter((word) => word.indexOf(searchText) !== -1)
          return valueArray.length > 0
        })

        return valueArray.length > 0 || salesSearch.length > 0
      })
      setDisplayData(filterData)
    } else {
      setDisplayData(companies)
    }
  }, [companies, searchText])
  /**
   *
   * @param data
   * @returns
   */
  const CompanyDetail = (params: any): JSX.Element => {
    return (
      <FunctionLabel
        label={params.data.name}
        clickHandler={() => {
          setIsDetailVisible(params.data.id)
        }}
      />
    )
  }
  /**
   *
   * @param params
   * @returns
   */
  const MemoInput = (params: any): JSX.Element => {
    return (
      <FunctionLabel
        label={'メモ'}
        clickHandler={() => {
          setIsMemoVisible(params.data.id)
        }}
      />
    )
  }
  /**
   *
   * @param data
   * @returns
   */
  const Frequency = (data: any): JSX.Element => (
    <span>{dateToString(data.data.update_at)}</span>
  )
  /**
   *
   * @param data
   * @returns
   */
  const SalesComponent = (data: any): JSX.Element => {
    const salesList: SalesEntityType[] = data.data.sales
    const salesLength = salesList.length
    if (data.columnNo <= salesLength) {
      const salesName =
        salesList[data.columnNo - 1].last_name +
        ' ' +
        salesList[data.columnNo - 1].first_name

      return <span>{salesName}</span>
    } else {
      return <span>{''}</span>
    }
  }
  /**
   *
   * @param params
   * @returns
   */
  const alertStyle = (params: CellClassParams<any, any>) => {
    /**
     * 更新日を過ぎている場合は赤
     * HIGH: 1ヶ月 MIDDLE: 3ヶ月 LOW: 6ヶ月
     */
    const updateDate = new Date(params.data.update_at)
    // 型付けしたうえで辞書型に変更
    const limitPeriod =
      params.data.frequency === 'HIGH'
        ? 1
        : params.data.frequency === 'MIDDLE'
          ? 3
          : params.data.frequency === 'LOW'
            ? 6
            : null
    if (limitPeriod === null) {
      return null
    }
    // 本日の日付
    const today = new Date()
    // 更新リミット
    updateDate.setMonth(updateDate.getMonth() + limitPeriod)
    // 期限
    if (updateDate < today) {
      return { backgroundColor: '#E79999' }
    } else {
      return null
    }
  }
  /**
   *
   * @param data
   * @returns
   */
  const TagComponent = (data: any): JSX.Element => {
    const tagType: string = data.data.tag
    return <span>{tagConvert(tagType)}</span>
  }
  const gridStyle = useMemo(() => ({ height: '700px' }), [])
  /**
   *
   */
  const [columnDefs] = useState<ColDef[]>([
    { headerName: '会社名', field: 'name', cellRenderer: CompanyDetail },
    {
      headerName: '打ち合せ',
      field: 'frequency',
      width: 120,
      cellRenderer: MemoInput
    },
    {
      headerName: '発起',
      field: 'update_at',
      width: 150,
      cellStyle: alertStyle,
      cellRenderer: Frequency
    },
    { headerName: '更新回数', width: 120, field: 'counter' },
    { headerName: '配信タグ', field: 'tag', cellRenderer: TagComponent },
    {
      headerName: '営業 1',
      field: 'sales_1',
      cellRenderer: SalesComponent,
      cellRendererParams: { columnNo: 1 }
    },
    {
      headerName: '営業 2',
      field: 'sales_2',
      cellRenderer: SalesComponent,
      cellRendererParams: { columnNo: 2 }
    },
    {
      headerName: '営業 3',
      field: 'sales_3',
      cellRenderer: SalesComponent,
      cellRendererParams: { columnNo: 3 }
    },
    {
      headerName: '営業 4',
      field: 'sales_4',
      cellRenderer: SalesComponent,
      cellRendererParams: { columnNo: 4 }
    },
    {
      headerName: '営業 5',
      field: 'sales_5',
      cellRenderer: SalesComponent,
      cellRendererParams: { columnNo: 5 }
    }
  ])
  return (
    <div style={gridStyle} className="ag-theme-alpine">
      <AgGridReact
        headerHeight={30}
        rowHeight={30}
        rowData={displayData}
        columnDefs={columnDefs}
      />
    </div>
  )
}
