import { CompanyEntityType } from 'types/api/company'
import { checkSalesPersons } from './checkSalesPersons'

const isEntity = (data: string | null): boolean => {
  return data === null || data === ''
}
export const checkInsertCompany = (data: CompanyEntityType): void => {
  // 会社情報
  if (isEntity(data.name) || isEntity(data.tag))
    throw new Error('必須項目が不足しています。')
  // 営業情報
  checkSalesPersons(data.sales)
}
