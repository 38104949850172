import React from 'react'
import { Grid } from '@mui/material'

type Props = {
  children: JSX.Element
}
export const BaseModal = (props: Props): JSX.Element | null => {
  const { children } = props
  return (
    <div>
      <div
        className={'w-full h-full absolute bg-black opacity-70 top-0 left-0'}
      />
      <Grid
        container
        position={'fixed'}
        top={0}
        left={0}
        alignItems={'center'}
        justifyContent={'center'}
        height={'100vh'}
        zIndex={2}
      >
        {children}
      </Grid>
    </div>
  )
}
