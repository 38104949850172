import { CompanyEntityType } from 'types/api/company'

export const checkSalesPersons = (data: CompanyEntityType['sales']): void => {
  // 営業は1名以上の必須
  const salesCheck = data.filter(
    (sales) =>
      !(
        sales.cell_phone === '' &&
        sales.department === '' &&
        sales.first_name === '' &&
        sales.last_name === '' &&
        sales.mail === ''
      )
  )
  if (!(salesCheck.length > 0)) {
    throw new Error('営業は1名以上登録してください')
  }
  // 姓、メールアドレスは必須
  const lastNameCheck = data.filter(
    (sales) =>
      (sales.cell_phone !== '' ||
        sales.department !== '' ||
        sales.first_name !== '' ||
        sales.last_name !== '' ||
        sales.mail !== '') &&
      (sales.last_name === '' || sales.mail === '')
  )
  if (lastNameCheck.length > 0) {
    throw new Error('営業情報の姓、メールアドレスは必須項目です')
  }
}
