import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components/atoms/Button'
import useModal from 'hooks/useModals'
import { AuthState } from 'recoil/atoms/auth.atom'
import { useRecoilState } from 'recoil'
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react'
import { downloadCompanyCSV, downloadCSVTemplate } from 'utils/csvUtil'
import { CompanyState } from 'recoil/atoms/companies.atom'
type Props = {
  children: JSX.Element
}
export const MainPage = (props: Props): JSX.Element => {
  const { children } = props
  const navigate = useNavigate()
  const [, setIsDetailVisible] = useModal('detail')
  const [, setIsSignUp] = useModal('signup')
  const [auth] = useRecoilState(AuthState)
  const [open, setOpen] = useState(false)
  const [companies] = useRecoilState(CompanyState)

  const logout = (): void => {
    localStorage.removeItem('token')
    navigate('/login')
  }
  const signUpHandler = () => {
    setIsSignUp(true)
  }
  const csvInputHandler = () => {}
  const csvOutputHandler = () => {
    downloadCompanyCSV(companies)
  }
  const csvTemplateDownload = () => {
    downloadCSVTemplate()
  }
  const menuOpener = () => setOpen((prev) => !prev)
  const menuList = () => {
    const common = 'p-2 border shadow text-xl hover:cursor-pointer rounded '
    const standard =
      'text-gray hover:text-white hover:bg-[#FE6BB0] hover:border-[#FE6BB0]'
    const logoutStyle =
      'text-white bg-[#333] hover:bg-[#555] hover:border-[#555]'
    if (auth !== null && auth === 'ADMIN') {
      return [
        {
          title: 'ユーザー登録',
          handler: signUpHandler,
          style: common + standard
        },
        {
          title: 'CSV入力',
          handler: csvInputHandler,
          style: common + standard
        },
        {
          title: 'CSV出力',
          handler: csvOutputHandler,
          style: common + standard
        },
        {
          title: 'CSVテンプレート',
          handler: csvTemplateDownload,
          style: common + standard
        },
        { title: 'ログアウト', handler: logout, style: common + logoutStyle }
      ]
    }
    return [
      { title: 'ログアウト', handler: logout, style: common + logoutStyle }
    ]
  }
  const Header = (): JSX.Element => {
    return (
      <>
        <Stack
          className={'bg-pink-200 text-white p-2'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} spacing={4}>
            <span className={'text-4xl font-bold text-gray-50'}>Stacks</span>
            <Stack spacing={2} direction={'row'} alignItems={'flex-end'}>
              <div className={'w-32'}>
                <Button
                  color={'peach'}
                  label={'会社登録'}
                  clickHandler={() => {
                    setIsDetailVisible(undefined)
                  }}
                />
              </div>
            </Stack>
          </Stack>
          <MenuIcon onClick={menuOpener} className={'hover:cursor-pointer'} />
        </Stack>
        {open && (
          <div className={'z-10'}>
            <div
              className={'w-full h-full absolute bg-black opacity-70 top-0'}
              onClick={menuOpener}
            />
            <div className={'absolute right-0 bg-white top-0 p-2 w-72 h-full'}>
              <div className={'flex justify-between'}>
                <div className={'font-bold'}>ユーザー</div>
                {/* TODO: ログインユーザー名をDBに追加 */}
                <div>
                  {auth !== null && auth === 'ADMIN' ? '管理者' : '一般'}
                </div>
              </div>
              <nav className={'mt-8'}>
                <ul className={'space-y-2'}>
                  {menuList().map((data) => (
                    <li
                      onClick={() => {
                        menuOpener()
                        data.handler()
                      }}
                      className={data.style}
                    >
                      {data.title}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        )}
      </>
    )
  }
  return (
    <Stack direction={'column'} sx={{ minWidth: '800px' }}>
      <Header />
      <div className={'p-2'}>{children}</div>
    </Stack>
  )
}
