import { Grid, Stack } from '@mui/material'

type Props = {
  children: JSX.Element
}
export const AuthPage = (props: Props): JSX.Element => {
  const { children } = props
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'center'}
      height={'100vh'}
    >
      <Stack
        alignItems={'center'}
        className={'bg-pink-200'}
        sx={{
          width: 400,
          borderRadius: '5px',
          boxShadow: 4,
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <span className={'font-mono text-6xl font-black m-3'}>Stacks</span>
        <Stack alignItems={'center'} height={'100%'} width={'100%'}>
          {children}
        </Stack>
      </Stack>
    </Grid>
  )
}
