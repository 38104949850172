import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Login } from 'components/pages/Login'
import { CustomerIntroduction } from 'components/pages/CustomerIntroduction'
import { NotFoundPage } from 'components/pages/NotFoundPage'
import { SignUp } from 'components/modals/SignUp'
import { AuthState } from 'recoil/atoms/auth.atom'
import { useRecoilState } from 'recoil'

export const Routing = () => {
  const location = useLocation()

  const RouteAuthGuard = (props: { component: React.ReactNode }) => {
    const { component } = props
    const [_, setAuth] = useRecoilState(AuthState)
    const localToken = localStorage.getItem('auth')
    useEffect(() => {
      if (localToken != null) {
        setAuth(JSON.parse(localToken).authority)
      }
    }, [])
    const authUser = localToken != null
    return authUser ? (
      <>{component}</>
    ) : (
      <Navigate to={'/login'} state={{ from: location }} replace={false} />
    )
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to={'/login'} state={{ from: location }} replace={false} />
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route
        path="/customer-introduction"
        element={<RouteAuthGuard component={<CustomerIntroduction />} />}
      />
      <Route
        path="*"
        element={<RouteAuthGuard component={<NotFoundPage />} />}
      />
    </Routes>
  )
}
