import { useState } from 'react'
import { Stack } from '@mui/material'
import { MemoModal } from 'components/modals/MemoModal'
import { CompanyDetailModal } from 'components/modals/CompanyDetailModal'
import { MainPage } from 'components/templates/MainPage'
import { CompanyList } from 'components/molecules/CompanyList'
import { SearchInput } from 'components/atoms/SearchInput'
import { LoadingOverlay } from 'components/modals/LoadingOverlay'
import { SignUp } from 'components/modals/SignUp'
/**
 *
 * @returns
 */
export const CustomerIntroduction = (): JSX.Element => {
  const [searchText, setSearchText] = useState<string>('')
  /**
   *
   */
  return (
    <>
      <MainPage>
        <Stack spacing={2}>
          <SearchInput searchText={searchText} setHandler={setSearchText} />
          <Stack spacing={1}>
            <CompanyList searchText={searchText} />
          </Stack>
        </Stack>
      </MainPage>
      <MemoModal />
      <CompanyDetailModal />
      <LoadingOverlay />
      <SignUp />
    </>
  )
}
