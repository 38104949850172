import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { BaseModal } from './_BaseModal'
import { Button } from 'components/atoms/Button'
import { SelectedCompany } from 'components/molecules/SelectedCompany'
import { SelectedSalesPersons } from 'components/molecules/SelectedSalesPersons'
import { Memo } from 'components/atoms/Memo'
import { useCallback, useEffect } from 'react'
import { erase, insert, update } from 'api/company.api'
import { getAll as getCompanyData } from 'api/company.api'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { CompanyState } from 'recoil/atoms/companies.atom'
import useModal from 'hooks/useModals'
import { CompanyEntityType } from 'types/api/company'
import { LoadingState } from 'recoil/atoms/loading.atom'
import swal from 'sweetalert2'
import { checkUpdateCompany } from 'utils/checkUpdateCompany'
import { checkInsertCompany } from 'utils/checkInsertCompany'
import { AuthState } from 'recoil/atoms/auth.atom'

export const CompanyDetailModal = (): JSX.Element | null => {
  const { handleSubmit, control, register, reset, getValues } =
    useForm<CompanyEntityType>()
  const [isVisible, setIsVisible] = useModal('detail')
  const setIsLoading = useSetRecoilState(LoadingState)
  const [companies, setCompanies] = useRecoilState(CompanyState)
  const [auth] = useRecoilState(AuthState)
  useEffect(() => {
    if (isVisible === undefined) {
      reset({
        memo: '',
        update_at: '',
        version: '',
        id: undefined,
        name: '',
        frequency: 'NONE',
        tag: null,
        post_code: null,
        address: null,
        home_phone: null,
        homepage: null,
        specialty: null,
        sales: [
          {
            cell_phone: '',
            department: '',
            first_name: '',
            last_name: '',
            is_deleted: false,
            mail: '',
            id: null
          }
        ]
      })
    } else if (isVisible !== null) {
      const findCompany = companies.find((company) => company.id === isVisible)
      findCompany && reset({ ...findCompany, memo: '' })
    }
  }, [reset, isVisible, companies])
  const isNew = getValues('id') === undefined
  const nullConvert = (str: string | null) =>
    str === '' || str === null ? null : Boolean(str)
  const onSubmit = async (data: any) => {
    setIsLoading(true)
    data.dispatch_license = nullConvert(data.dispatch_license)
    data.p_mark = nullConvert(data.p_mark)
    data.isms = nullConvert(data.isms)

    try {
      if (isNew) {
        checkInsertCompany(data)
        await insert(data).then((response) => {
          getCompanyData().then(async (companyData) => {
            setCompanies(companyData.data)
            await swal.fire({
              icon: 'info',
              text: '会社情報を登録しました。'
            })
            setIsVisible(response.data.id)
          })
        })
      } else {
        checkUpdateCompany(data)
        await update(data).then(() =>
          getCompanyData().then(async (companyData) => {
            setCompanies(companyData.data)
            await swal.fire({
              icon: 'info',
              text: '会社情報を更新しました。'
            })
          })
        )
      }
    } catch (err: any) {
      await swal.fire({ icon: 'error', text: err.message })
    }
    setIsLoading(false)
  }
  const uncontrollableMemo = useCallback(() => {
    const findData = companies.find((data) => data.id === isVisible)
    return findData ? findData.memo : ''
  }, [companies, isVisible])
  const closeHandler = () => {
    setIsVisible(null)
  }
  const deleteHandler = async () => {
    setIsLoading(true)
    try {
      const findData = companies.find((data) => data.id === isVisible)
      if (findData) {
        await erase(findData).then(() => {
          getCompanyData().then(async (companyData) => {
            setCompanies(companyData.data)
            await swal.fire({
              icon: 'info',
              text: '会社情報を削除しました。'
            })
            setIsVisible(null)
          })
        })
      }
    } catch (err: any) {
      await swal.fire({ icon: 'error', text: err.message })
    }
    setIsLoading(false)
  }
  const FooterButtons = (): JSX.Element => {
    return (
      <Stack direction={'row'} justifyContent={'space-around'}>
        {auth !== null && auth === 'ADMIN' && !isNew && (
          <div className={'w-40'}>
            <Button
              color="red"
              clickHandler={deleteHandler}
              label={'削除'}
              type={'button'}
            />
          </div>
        )}
        <div className={'w-40'}>
          {isNew ? (
            <Button color="green" label={'新規作成'} />
          ) : (
            <Button color="green" label={'更新'} />
          )}
        </div>
        <div className={'w-40'}>
          <Button
            color="dark"
            clickHandler={closeHandler}
            label={'閉じる'}
            type={'button'}
          />
        </div>
      </Stack>
    )
  }
  return isVisible !== null ? (
    <>
      <BaseModal>
        <Stack
          direction={'column'}
          spacing={2}
          sx={{ borderRadius: '5px', background: '#f3f5f7', padding: '20px' }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'column'} spacing={2}>
              <Stack direction={'row'} spacing={2}>
                <Stack spacing={2}>
                  <Stack spacing={0.6}>
                    <div>会社情報</div>
                    <SelectedCompany
                      register={register}
                      getValues={getValues}
                      isNew={isNew}
                    />
                  </Stack>
                  <Stack spacing={0.6} height={'320px'}>
                    <div>営業情報</div>
                    <SelectedSalesPersons
                      register={register}
                      control={control}
                    />
                  </Stack>
                </Stack>
                <Stack direction={'column'} spacing={2}>
                  <div>メモ</div>
                  <Memo
                    register={register}
                    placeHolder={'先方担当者：\n注力情報：\n業界：'}
                  />
                  <Memo value={uncontrollableMemo()} disabled />
                </Stack>
              </Stack>
              <FooterButtons />
            </Stack>
          </form>
        </Stack>
      </BaseModal>
    </>
  ) : null
}
