import { UseFormRegister } from 'react-hook-form'
import { CompanyEntityType } from 'types/api/company'

type Props = {
  register: UseFormRegister<CompanyEntityType>
  tag: 'dispatch_license' | 'p_mark' | 'isms'
}
export const BooleanSelector = (props: Props): JSX.Element => {
  const { register, tag } = props
  console.log(tag, String(null))
  const tagList = [
    { key: '', value: '-' },
    { key: 'true', value: 'あり' },
    { key: 'false', value: 'なし' }
  ]
  return (
    <select
      className={'h-full w-full border-none outline-none'}
      {...register(tag)}
    >
      {tagList.map((data) => (
        <option value={String(data.key)} key={String(data.key)}>
          {data.value}
        </option>
      ))}
    </select>
  )
}
