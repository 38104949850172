import { guardClient } from './_axios'
import { CompanyEntityType } from 'types/api/company'

const API_COMPANY = '/company'

/**
 * 会社情報の全件取得
 * [get]/company/
 */
export const getAll = async () => {
  const response = await guardClient.get(API_COMPANY)
  return response.data
}
/**
 * 会社情報の新規追加
 * [post]/company
 */
export type NewRequestType = CompanyEntityType
export const insert = async (request: NewRequestType) => {
  const { ...params } = request
  return await guardClient.post(API_COMPANY, params)
}
/**
 * 会社情報の更新
 * [patch]/company/{id}
 */
export type UpdateRequestType = CompanyEntityType
export const update = async (request: UpdateRequestType) => {
  const { id, ...params } = request
  const response = await guardClient.patch(API_COMPANY + '/' + id, params)
  return response.data
}
/**
 * 会社情報の削除
 * [delete]/company/{id}
 */
export type EraseRequestType = { id: number; version: string }
export const erase = async (request: EraseRequestType) => {
  const { id, version } = request
  const response = await guardClient.delete(API_COMPANY + '/' + id, {
    data: { version }
  })
  return response.data
}
/**
 * メモ更新
 * [Patch]/memo/{id}
 */
type MemoUpdateType = {
  id: number
  memo: string
  version: string
}
export const updateMemo = async (request: MemoUpdateType) => {
  const { id, ...params } = request
  const response = await guardClient.patch(API_COMPANY + '/memo/' + id, params)
  return response.data
}
